export const randomShopifyProducts = [
  {
    url: 'https://www.fashionnova.com/products/jessie-oversize-flannel-top-black-combo',
    id: '1322337009788',
  },
  {
    url: 'https://www.mcgeeandco.com/products/gemma-chair',
    id: '6599740653643',
  },
  {
    url: 'https://colourpop.com/products/no-filter-concealer',
    id: '224928825369',
  },
  {
    url: 'https://www.peets.com/products/major-dickasons-blend',
    id: '5287653670956',
  },
  {
    url: 'https://shop.mindbodygreen.com/products/magnesium',
    id: '4169543090274',
  },
  {
    url: 'https://www.spartan.com/products/spartan-ocr-vibram-elite-shoe-mens-cinder-nightlight',
    id: '6677509406818',
  },
  {
    url: 'https://www.vitalproteins.com/products/marine-collagen-peptides',
    id: '10674705222',
  },
  {
    url: 'https://www.sanrio.com/products/hello-kitty-10-plush-classic-series',
    id: '5761856700572',
  },
  {
    url: 'https://www.americangirl.com/products/american-girl-2022-sapphire-splendor-collector-doll-hjl15',
    id: '7400705949886',
  },
  {
    url: 'https://www.thehonestkitchen.com/products/whole-food-clusters-grain-free-chicken-dry-dog-food/products.json',
    id: '7558719471866',
  },
  {
    url: 'https://www.yellowoctopus.com.au/products//choco-holic-gift-hamper',
    id: '6586676150357',
  },
  {
    url: 'https://flyinmiata.com/products/electronic-boost-control',
    id: '7723201462527',
  },
  {
    url: 'https://www.svsound.com/products/prime-bookshelf',
    id: '3281356163',
  },
  {
    url: 'https://www.elegoo.com/products/elegoo-neptune-3-fdm-3d-printer',
    id: '6908328247344',
  },
  {
    url: 'https://plugable.com/products/usb4-hub3a',
    id: '7493291999463',
  },
  {
    url: 'https://youtooz.com/products/mr-krabs-and-the-smallest-violin',
    id: '7441787814088',
  },
  {
    url: 'https://iconhookah.com/products/black-disposable-hookah-mouth-tips-pack-of-100-hookah-tips',
    id: '6610166284465',
  },
  {
    url: 'https://passionplanner.com/products/weekly-2023-annual-celestial-blue',
    id: '6938870775867',
  },
  {
    url: 'https://languageposters.com/products/how-to-eat-play-and-live-in-spanish-poster',
    id: '4505445793853',
  },
  {
    url: 'https://beistravel.com/products/the-26-check-in-roller-in-pink',
    id: '7051149246600',
  },
  {
    url: 'https://chalicecollectibles.com/products/funko-pop-chalice-collectibles-exclusive-dragon-ball-super-ssgss-goku-kamehameha-563',
    id: '3949079560234',
  },
  {
    url: 'https://www.lorex.com/products/w881aad-4k-spotlight-indoor-outdoor-wi-fi-security-camera',
    id: '7549262069910',
  },
  {
    url: 'https://www.costumesupercenter.com/products/star-trek-deluxe-spock-adult-costume',
    id: '7544774492322',
  },
  {
    url: 'https://ifyousayido.com/products/lighted-fiber-optic-wands-for-wedding-send-offs-anniversary-celebrations',
    id: '6567265140795',
  },
  {
    url: 'https://getmaude.com/products/vibe-shine-kit',
    id: '6667558322255',
  },
  {
    url: 'https://www.celestron.com/products/pentaview-lcd-digital-microscope',
    id: '9942995465',
  },
  {
    url: 'https://soliswifi.co/products/solis-lite-hotspot',
    id: '6838340616378',
  },
  {
    url: 'https://bitcoinmerch.com/products/antminer-s9k-13-5th-s',
    id: '1920564691034',
  },
  {
    url: 'https://canvasprints.com/products/11oz-ceramic-mug',
    id: '6786085683287',
  },
  {
    url: 'https://blade-tech.com/products/total-eclipse-2-modular-holster',
    id: '6619796406362',
  },
  {
    url: 'https://www.thelacmastore.org/products/alexander-calder-los-angeles-county-museum-of-art-april-1-1965-poster',
    id: '163221265',
  },
  {
    url: 'https://overhalfsale.com/products/4-pack-disposable-wasp-traps-outdoor-bee-catcher-killer-for-outside-carpenter-bee-killer',
    id: '7376302211243',
  },
  {
    url: 'https://brookfieldwi.clothesmentor.com/products/belt-bag-by-lululemon-size-medium-223-22370-27457',
    id: '7245639647277',
  },
];
