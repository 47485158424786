export const randomAmazonProducts = [
  {
    url: 'https://www.amazon.com/Blink-Mini-White-2Cam/dp/B07X27VK3D',
    id: 'B07X27VK3D',
  },
  {
    url: 'https://www.amazon.com/Apple-iPhone-11-64GB-Black/dp/B07ZPKN6YR',
    id: 'B07ZPKN6YR',
  },
  {
    url: 'https://www.amazon.com/DUDE-Wipes-Flushable-Extra-Large-Eucalyptus/dp/B08KHY1PKR',
    id: 'B08KHY1PKR',
  },
  {
    url: 'https://www.amazon.com/Silonn-Countertop-Portable-Self-cleaning-Bullet-shaped/dp/B08ZYJ8CRX',
    id: 'B08ZYJ8CRX',
  },
  {
    url: 'https://www.amazon.com/Mojang-Minecraft/dp/B00992CF6W',
    id: 'B00992CF6W',
  },
  {
    url: 'https://www.amazon.com/Apple-Barrel-Acrylic-Assorted-Colors/dp/B006AT7P6M',
    id: 'B006AT7P6M',
  },
  {
    url: 'https://www.amazon.com/Friends-Lovers-Big-Terrible-Thing/dp/B09RQ4L751',
    id: 'B09RQ4L751',
  },
  {
    url: 'https://www.amazon.com/Rain-X-5079281-2-Latitude-Water-Repellency/dp/B016NA9Y4S',
    id: 'B016NA9Y4S',
  },
  {
    url: 'https://www.amazon.com/Pampers-Sensitive-Water-Based-Pop-Top-Refill/dp/B07JRBSPZ3',
    id: 'B07JRBSPZ3',
  },
  {
    url: 'https://www.amazon.com/Neutrogena-Cleansing-Towelettes-Waterproof-Alcohol-Free/dp/B00U2VQZDS',
    id: 'B00U2VQZDS',
  },
  {
    url: 'https://www.amazon.com/Starts-Us-Novel-Ends/dp/1668001225',
    id: '1668001225',
  },
  {
    url: 'https://www.amazon.com/Vision-Indoor-Outdoor-Camera-Assistant/dp/B08R59YH7W',
    id: 'B08R59YH7W',
  },
  {
    url: 'https://www.amazon.com/Midnights-Moonstone-Blue-Taylor-Swift/dp/B0BFFB1G2R',
    id: 'B0BFFB1G2R',
  },
  {
    url: 'https://www.amazon.com/iOttie-Dashboard-Windshield-Samsung-Smartphones/dp/B0875RKTQF',
    id: 'B0875RKTQF',
  },
  {
    url: 'https://www.amazon.com/crocs-Unisex-Classic-Black-Women/dp/B0014C5S7S',
    id: 'B0014C5S7S',
  },
  {
    url: 'https://www.amazon.com/Decision-Pregnancy-Christmas-Birthday-Double-Sided/dp/B08XQ2S1TR',
    id: 'B08XQ2S1TR',
  },
  {
    url: 'https://www.amazon.com/Fire-HD-10-tablet/dp/B08BX7FV5L',
    id: 'B08BX7FV5L',
  },
  {
    url: 'https://www.amazon.com/Spectrum-Math-Workbook-Grade-3/dp/B082DKWL2T',
    id: 'B082DKWL2T',
  },
  {
    url: 'https://www.amazon.com/music/player/albums/B016KV7OC4',
    id: 'B016KV7OC4',
  },
  {
    url: 'https://www.amazon.com/all-new-fire-tv-stick-4k-with-alexa-voice-remote/dp/B08XVYZ1Y5',
    id: 'B08XVYZ1Y5',
  },
  {
    url: 'https://www.amazon.com/Cardboard-Collectible-Baseball-Football-Basketball/dp/B0B4TVNWNR',
    id: 'B0B4TVNWNR',
  },
  {
    url: 'https://www.amazon.com/Amazon-eGift-Card-Birthday-Animated/dp/B09V2Y41ZR',
    id: 'B09V2Y41ZR',
  },
  {
    url: 'https://www.amazon.com/CELSIUS-Sparkling-Orange-Fitness-Drink/dp/B007R8XGJA',
    id: 'B007R8XGJA',
  },
  {
    url: 'https://www.amazon.com/Personalized-Accessories-Engraved-Anti-Lost-Stainless/dp/B096G34R6P',
    id: 'B096G34R6P',
  },
  {
    url: 'https://www.amazon.com/AmazonBasics-Performance-Alkaline-Batteries-Count/dp/B00MNV8E0C',
    id: 'B00MNV8E0C',
  },
  {
    url: 'https://www.amazon.com/Amazon-Basics-Lightweight-Microfiber-14-Inch/dp/B0154ASID6',
    id: 'B0154ASID6',
  },
  {
    url: 'https://www.amazon.com/iHealth-COVID-19-Authorized-Non-invasive-Discomfort/dp/B09KZ6TBNY',
    id: 'B09KZ6TBNY',
  },
  {
    url: 'https://www.amazon.com/Verity-Colleen-Hoover-ebook/dp/B09H6T8LTR',
    id: 'B09H6T8LTR',
  },
  {
    url: 'https://www.amazon.com/Stanley-Adventure-Quencher-Travel-Tumbler/dp/B09V5Q3Q21',
    id: 'B09V5Q3Q21',
  },
  {
    url: 'https://www.amazon.com/Hearst-Magazines-Food-Network-Magazine/dp/B001THPA58',
    id: 'B001THPA58',
  },
  {
    url: 'https://www.amazon.com/Top-Gun-Maverick-Tom-Cruise/dp/B09XZD472R',
    id: 'B09XZD472R',
  },
  {
    url: 'https://www.amazon.com/Changing-Control-Lighting-Flexible-Decoration/dp/B09V366BDY',
    id: 'B09V366BDY',
  },
  {
    url: 'https://www.amazon.com/AmazonBasics-Bright-Multipurpose-Copy-Paper/dp/B07K8WHH5J',
    id: 'B07K8WHH5J',
  },
  {
    url: 'https://www.amazon.com/AmazonBasics-Commercial-Patio-Heater-Slate/dp/B010VFKZEO',
    id: 'B010VFKZEO',
  },
  {
    url: 'https://www.amazon.com/Earth-Rated-Lavender-Scented-Completely/dp/B00BSYR7K8',
    id: 'B00BSYR7K8',
  },
  {
    url: 'https://www.amazon.com/Microsoft-Personal-12-month-subscription-Auto-renewal/dp/B07F3TQ6DQ',
    id: 'B07F3TQ6DQ',
  },
  {
    url: 'https://www.amazon.com/KEMNGO-Lightweight-Oversized-Snowflake-Outerwear/dp/B0BKKDPV5R',
    id: 'B0BKKDPV5R',
  },
  {
    url: 'https://www.amazon.com/Panini-Soccer-Factory-50-Pack-Sticker/dp/B0BC272PV3',
    id: 'B0BC272PV3',
  },
  {
    url: 'https://www.amazon.com/Brita-35503-Standard-Replacement-Filters/dp/B00004SU18',
    id: 'B00004SU18',
  },
  {
    url: 'https://www.amazon.com/LeapFrog-Learning-Friends-Words-Green/dp/B07B6ZN7P8',
    id: 'B07B6ZN7P8',
  },
  {
    url: 'https://www.amazon.com/PlayStation-Store-Gift-Card-Digital/dp/B00GAC1D2G',
    id: 'B00GAC1D2G',
  },
];
